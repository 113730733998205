<!-- 形象展示 -->
<template>
  <div class="container mt-80-mb-140 container-box">
    <div class="row">
      <div
        class="col-12 col-xxl-6 col-xl-6 col-md-6 video-item"
        v-for="item in videoList"
        :key="item"
      >
        <VideoItem class="index-video" :video="item"> </VideoItem>
      </div>
    </div>
  </div>
  <PaginationBox
    :total="pageObj.total_count"
    v-model:currentPage="currentPage"
  ></PaginationBox>
</template>
<script setup>
import VideoItem from '@/components/VideoItem.vue'
import PaginationBox from '@/components/PaginationBox'
import { getVideo } from '@/server/video'
import { onMounted, ref, watch } from 'vue'

const videoList = ref([])
const currentPage = ref(1)
const pageObj = ref({})

onMounted(() => {
  fetchVideo()
})

const fetchVideo = async () => {
  const r = await getVideo(currentPage.value, 2)
  videoList.value = r.list
  pageObj.value = r.cur_page
}

watch(currentPage, async () => {
  const r = await getVideo(currentPage.value, 2)
  videoList.value = r.list
  pageObj.value = r.cur_page
})
</script>
<style lang="stylus" scoped>
.video-item
  margin-bottom 20px
  height 350px
.container-box
  padding-bottom 130px
  @media screen and (max-width 768px) {
    padding-bottom 90px
  }
</style>
